import * as React from "react"
import { Helmet } from "react-helmet";
import * as styles from "../style/index.module.scss"

// markup
const IndexPage = () => {
  return (
    <main className={styles.wrap}>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>bruno lima, ui developer</title>
        <meta rel="preload" name="description" content="Bruno Lima is a UI Developer." />
        <link rel="stylesheet" href="https://use.typekit.net/gfj6uyv.css"></link>
      </Helmet>
      <h1 className={styles.title}>bruno lima</h1>
      <p className={styles.abstract}>
        ui developer 
        <span className={styles.abstract__divide} aria-hidden>//</span> 
        <a className={styles.abstract__mail} 
          title="Send me an email." 
          href="mailto:hi@brunolima.io"
        >
          hi@brunolima.io
        </a>
      </p>
    </main>
  )
}

export default IndexPage
